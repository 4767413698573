import get from 'lodash/get'
import head from 'lodash/head'
import React from 'react'
import { emotion as animate } from './animate'
import Base, { emotion as base } from './base'
import Layout, { emotion as layout } from './layout'
import Localized from './localized'
import Typography, { emotion as typography } from './typography'

/**
 * Used to set global styles using emotion global object
 */
export default function Global() {
  return (
    <>
      <Base />
      <Layout />
      <Typography />
      <Localized />
    </>
  )
}

const styles = {
  base,
  layout,
  typography,
  animate,
}

/**
 * Exported emotion styles that can be reused
 */
export const global = (key) => get(styles, head(key))
