import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import tw from 'twin.macro'
import { HTMLType, StyleType } from '../utils/prop-types'
import { inlineTypography } from './text'
import Heading from './heading'
import Button from './button'
import { global } from '../styles/global'
import { ReactComponent as Info } from '../images/icon-info.svg'

const CookieConsent = ({ cookieName, label, buttonLabel, style }) => {
  const [cookies, setCookie] = useCookies()
  const [hidden, setHidden] = useState(true)
  const useSecure = (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV) === 'production'

  const consentCookies = useCallback(() => {
    setCookie(cookieName, true, { maxAge: 604800, path: '/', secure: useSecure, sameSite: 'lax' })
    window[`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`] = false
  }, [cookieName, setCookie, useSecure])

  useEffect(() => {
    setHidden(cookies[cookieName])
  }, [cookies, cookieName])

  return (
    <aside
      css={[
        hidden && tw`hidden`,
        tw`bg-smoke-grey bg-opacity-90 w-full fixed top-mobile-header py-6 z-10 lg:(bottom-0 top-auto)`,
        style,
      ]}
    >
      <div
        css={[
          global`layout.container`,
          tw`flex flex-col items-center space-y-8 lg:(flex-row space-y-0 space-x-16)`,
        ]}
      >
        <div css={tw`flex flex-row items-center space-x-4`}>
          <Info css={tw`flex-none`} />
          <Heading
            headingType="h6"
            content={label}
            style={[inlineTypography, tw`text-primary-500 font-light`]}
          />
        </div>
        <div css={tw`flex-none`}>
          <Button
            type="primary"
            size="xs"
            theme="navy-cyan"
            label={buttonLabel}
            onClick={consentCookies}
          />
        </div>
      </div>
    </aside>
  )
}

CookieConsent.defaultProps = {
  cookieName: 'cookie-consent',
}

CookieConsent.propTypes = {
  cookieName: PropTypes.string,
  label: HTMLType,
  buttonLabel: PropTypes.string,
  style: StyleType,
}

export default CookieConsent
