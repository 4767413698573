import flattenDeep from 'lodash/flattenDeep'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { css, theme as globalTheme } from 'twin.macro'
import { ReactComponent as Caret } from '../images/icon-caret-right.svg'
import { fluid, remToPx } from '../styles/typography'
import { lg } from '../utils/breakpoints'
import { hexToRGBA } from '../utils/colors'
import { StyleType } from '../utils/prop-types'
import Link from './link'

const Button = ({
  style,
  type,
  size,
  theme,
  label,
  link,
  modal,
  handleClick,
  disabled,
  hideCaret,
  buttonType,
  ...props
}) => {
  const typeStyles = {
    primary: css`
      ${tw`flex items-center font-medium text-white bg-primary-500`}
      span {
        ${tw`text-left`}
      }
      svg {
        ${tw`flex-none block fill-current stroke-0 text-secondary-500`}
      }
    `,
    'primary-reverse': css`
      ${tw`flex items-center font-medium text-white bg-primary-500`}
      span {
        ${tw`text-left order-1`}
      }
      svg {
        ${tw`flex-none block fill-current stroke-0 text-secondary-500 transform rotate-180!`}
      }
      &:hover {
        svg {
          ${tw`lg:-translate-x-1!`}
        }
      }
    `,
    secondary: css`
      ${tw`font-medium bg-transparent border text-secondary-500 border-secondary-500`}
      padding: 14px 14px;
      span {
        ${tw`hidden`}
      }
      svg {
        width: 10px;
        height: 10px;
        fill: ${tw`flex-none block fill-secondary stroke-0`};
      }
    `,
  }

  const sizeStyles = {
    xs: css`
      ${tw`h-10 text-sm leading-6 py-2`}
      span {
        ${tw`mx-6`}
      }
      svg {
        ${tw`mr-4`}
      }
    `,
    sm: css`
      ${tw`h-12 text-base leading-6`}
      padding-top: 14px;
      padding-bottom: 14px;
      span {
        margin-left: 28px;
        margin-right: 28px;
      }
      svg {
        margin-right: 20px;
      }
    `,
    base: css`
      ${tw`leading-md tracking-md py-3 lg:py-4`}
      ${fluid(remToPx(globalTheme`fontSize.mobile-sm`), remToPx(globalTheme`fontSize.base`))}
      span {
        ${tw`mx-6 lg:mx-8`}
      }
      svg {
        ${tw`mr-5 lg:mr-6`}
      }
    `,
    lg: css`
      ${tw`h-16 text-xl leading-8`}
      padding-top: 16px;
      padding-bottom: 16px;
      span {
        margin-left: 36px;
        margin-right: 36px;
      }
      svg {
        margin-right: 28px;
      }
    `,
  }

  const generateTheme = (t) => {
    const [from, to] = t.split('-')
    const mapping = {
      navy: {
        background: tw`bg-primary-500`,
        gradient: globalTheme`colors.primary.500`,
        text: tw`text-white`,
        caret: tw`text-secondary-500`,
      },
      white: {
        background: tw`bg-white`,
        gradient: globalTheme`colors.white`,
        text: tw`text-primary-500`,
        caret: tw`text-primary-500`,
      },
      grey: {
        background: tw`bg-white bg-opacity-60`,
        gradient: hexToRGBA(globalTheme`colors.white`, 60),
        text: tw`text-primary-500`,
        caret: tw`text-primary-500`,
      },
      cyan: {
        background: tw`bg-secondary-500`,
        gradient: globalTheme`colors.secondary.500`,
        text: tw`text-primary-500`,
        caret: tw`text-primary-500`,
      },
      yellow: {
        background: tw`bg-accent-500`,
        gradient: globalTheme`colors.accent.500`,
        text: tw`text-primary-500`,
        caret: tw`text-primary-500`,
      },
      navyopaque: {
        background: tw`bg-primary-500 bg-opacity-10`,
        gradient: hexToRGBA(globalTheme`colors.primary.500`, 10),
        text: tw`text-primary-500`,
        caret: tw`text-primary-500`,
      },
      transparent: {
        background: tw`bg-transparent`,
        gradient: 'rgba(0, 0, 0, 0)',
        text: tw`text-primary-500`,
        caret: tw`text-primary-500`,
      },
    }

    const fromColors = mapping[from]
    const toColors = mapping[to]

    return css`
      ${fromColors.background}
      ${fromColors.text}
      svg {
        ${fromColors.caret}
      }

      ${lg} {
        ${tw`transition-all duration-300 ease-in-out`}
        transition-property: background-position, color;
        background: linear-gradient(to left, ${fromColors.gradient} 50%, ${toColors.gradient} 50%);
        background-size: 205% 100%;
        background-position: right bottom;
        svg {
          ${tw`transition-all duration-300 ease-in-out`}
          transition-property: color, transform;
        }
        &:hover {
          ${tw`bg-left-bottom`}
          ${toColors.text}
          svg {
            ${toColors.caret}
            ${tw`transform translate-x-1`}
          }
        }
      }
    `
  }

  const disabledStyle = css`
    ${tw`cursor-wait pointer-events-none`}
  `
  const getComponent = () => {
    return link && !modal ? (
      <Link
        to={link}
        style={flattenDeep([
          typeStyles[type],
          sizeStyles[size],
          theme && generateTheme(theme),
          disabled && disabledStyle,
          style,
        ])}
        {...props}
      >
        <span>{label}</span>
        {!hideCaret && <Caret />}
      </Link>
    ):(
    <button
      onClick={handleClick}
      type={buttonType}
      css={flattenDeep([
        typeStyles[type],
        sizeStyles[size],
        theme && generateTheme(theme),
        disabled && disabledStyle,
        style,
      ])}
      {...props}
    >
      <span>{label}</span>
      {!hideCaret && <Caret />}
    </button>
    )
  }
  return <>{getComponent()}</>
}

Button.defaultProps = {
  buttonType: 'button',
}

Button.propTypes = {
  style: StyleType,
  type: PropTypes.oneOf(['primary', 'primary-reverse', 'secondary']),
  size: PropTypes.oneOf(['xs', 'sm', 'base', 'lg']),
  theme: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hideCaret: PropTypes.bool,
  buttonType: PropTypes.string,
  props: PropTypes.object,
}

export default Button
