import { Checkbox as MaterialCheckbox } from '@rmwc/checkbox'
import '@rmwc/checkbox/styles'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { styled, theme, css } from 'twin.macro'

const invalidStyle = css`
  .mdc-checkbox__background {
    --mdc-theme-secondary: ${theme`colors.red.500`};
    ${tw`border-red-500!`}
  }
`

const StyledMaterialCheckbox = styled(MaterialCheckbox)`
  ${tw`-ml-3`}
  .mdc-checkbox {
    --mdc-ripple-fg-size: 0 !important;
  }
  .mdc-checkbox__background {
    --mdc-theme-secondary: ${theme`colors.primary.500`};
    ${tw`rounded-none border border-primary-500 bg-primary-500`}
    &:before {
      ${tw`hidden!`}
    }
  }
  .mdc-checkbox__ripple {
    ${tw`hidden`}
  }
`

const FormCheckbox = ({ invalid, children, ...props }) => {
  return (
    <div css={tw`flex items-center`}>
      <StyledMaterialCheckbox css={invalid && invalidStyle} {...props} />
      {children}
    </div>
  )
}

FormCheckbox.propTypes = {
  invalid: PropTypes.bool,
  children: PropTypes.node,
  props: PropTypes.object,
}

export default FormCheckbox
