import PropTypes from 'prop-types'

const LinkType = PropTypes.shape({ label: PropTypes.string, link: PropTypes.string })

const RequiredLinkType = PropTypes.shape({ label: PropTypes.string, link: PropTypes.string })
  .isRequired

const HTMLType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({ html: PropTypes.string }),
  }),
])

const RequiredHTMLType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({ html: PropTypes.string }),
  }),
]).isRequired

const ChildrenType = PropTypes.node

const RequiredChildrenType = PropTypes.node.isRequired

const StyleType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.bool])),
])

const RequiredStyleType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.arrayOf([PropTypes.object]),
]).isRequired

const ImageType = PropTypes.oneOfType([
  PropTypes.shape({ url: PropTypes.string, format: PropTypes.string }),
  PropTypes.shape({ fluid: PropTypes.object, fixed: PropTypes.object, format: PropTypes.string }),
])

const RequiredImageType = PropTypes.oneOfType([
  PropTypes.shape({ url: PropTypes.string, format: PropTypes.string }),
  PropTypes.shape({ fluid: PropTypes.object, fixed: PropTypes.object }),
])

const SVGType = PropTypes.shape({ url: PropTypes.string, format: PropTypes.string })

const RequiredSVGType = PropTypes.shape({ url: PropTypes.string, format: PropTypes.string })
  .isRequired

export {
  LinkType,
  RequiredLinkType,
  HTMLType,
  RequiredHTMLType,
  ChildrenType,
  RequiredChildrenType,
  StyleType,
  RequiredStyleType,
  ImageType,
  RequiredImageType,
  SVGType,
  RequiredSVGType,
}
