import { css } from 'twin.macro'

export const emotion = {
  block: css`
    @keyframes slideInLeft {
      from {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        visibility: visible;
      }

      to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }
    }

    .slideInLeft {
      -webkit-animation-name: slideInLeft;
      animation-name: slideInLeft;
    }

    @keyframes slideInRight {
      from {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: visible;
      }

      to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }
    }

    .slideInRight {
      -webkit-animation-name: slideInRight;
      animation-name: slideInRight;
    }

    @keyframes slideOutLeft {
      from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }

      to {
        visibility: hidden;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
      }
    }

    .slideOutLeft {
      -webkit-animation-name: slideOutLeft;
      animation-name: slideOutLeft;
    }

    @keyframes slideOutRight {
      from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }

      to {
        visibility: hidden;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
      }
    }

    .slideOutRight {
      -webkit-animation-name: slideOutRight;
      animation-name: slideOutRight;
    }

    .animated {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }

    .animated.infinite {
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }

    .animated.delay-1s {
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }

    .animated.delay-2s {
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
    }

    .animated.delay-3s {
      -webkit-animation-delay: 3s;
      animation-delay: 3s;
    }

    .animated.delay-4s {
      -webkit-animation-delay: 4s;
      animation-delay: 4s;
    }

    .animated.delay-5s {
      -webkit-animation-delay: 5s;
      animation-delay: 5s;
    }

    .animated.fast {
      -webkit-animation-duration: 800ms;
      animation-duration: 800ms;
    }

    .animated.faster {
      -webkit-animation-duration: 500ms;
      animation-duration: 500ms;
    }

    .animated.slow {
      -webkit-animation-duration: 2s;
      animation-duration: 2s;
    }

    .animated.slower {
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
    }

    @media print, (prefers-reduced-motion: reduce) {
      .animated {
        -webkit-animation-duration: 1ms !important;
        animation-duration: 1ms !important;
        -webkit-transition-duration: 1ms !important;
        transition-duration: 1ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
      }
    }

    .animatable {
      transition: 0.25s transform ease-out;
    }
  `,
}
