import { HelmetDatoCms } from 'gatsby-source-datocms'
import PropTypes from 'prop-types'
import React from 'react'
import { usePageContext } from '../context/page-context'

function SEO({ pageTags, faviconTags, siteUrl, noIndex }) {
  const { supportedLocales, locale, originalPath } = usePageContext()

  const favicon = {
    tags: [
      ...faviconTags.filter((t) => t.attributes.name !== 'application-name'),
      { attributes: { name: 'application-name', content: 'bolttech.kr' }, tagName: 'meta' },
    ],
  }

  return (
    <HelmetDatoCms seo={pageTags} favicon={favicon}>
      <html lang={locale} />
      <meta name="naver-site-verification" content="e5e9043d15aa3fbe3e8828480a2c6469fae7e28e" />
      <link rel="canonical" href={`${siteUrl}/${locale}${originalPath}`} />
      {supportedLocales &&
        supportedLocales.map((l) => (
          <link key={l} rel="alternate" hrefLang={l} href={`${siteUrl}/${l}${originalPath}`} />
        ))}
      {noIndex && <meta name="robots" content="noindex" />}
    </HelmetDatoCms>
  )
}

SEO.propTypes = {
  pageTags: PropTypes.object,
  faviconTags: PropTypes.arrayOf(PropTypes.object),
  siteUrl: PropTypes.string,
  noIndex: PropTypes.bool,
}

export default SEO
