import { graphql, useStaticQuery } from 'gatsby'
import compact from 'lodash/compact'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { usePageContext } from '../context/page-context'
import Global from '../styles/global'
import DemoForm from './demo-form'
import EnquiryForm from './enquiry-form'
import Footer from './footer'
import Header from './header'
import SEO from './seo'
import CookieConsent from './cookie-consent'

const useSiteComponents = () => {
  const { locale } = usePageContext()
  const { site, datoCmsSite, en, ko } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
        globalSeo {
          siteName
          titleSuffix
          twitterAccount
          facebookPageUrl
          fallbackSeo {
            title
            description
            image {
              url
            }
            twitterCard
          }
        }
      }
      en: datoCmsKrShared(locale: { eq: "en" }) {
        logo {
          url
          alt
          title
        }
        mainNavLinks {
          link
          subLinks {
            link
            label
            descriptionNode {
              childMarkdownRemark {
                html
              }
            }
            image {
              url
              alt
              title
            }
          }
          label
        }
        demo {
          link
          label
        }
        socialMediaLabel
        socialMedia {
          title
          image {
            url
            alt
            title
          }
          accountLink {
            link
            label
          }
        }
        footerLogo {
          url
          alt
          title
        }
        exploreLabel
        exploreLinks {
          link
          label
        }
        wwdLabel
        wwdLinks {
          link
          label
        }
        legalLabel
        legalLinks {
          link
          label
        }
        globalSiteLabel
        globalSiteLink {
          link
          label
        }
        copyright
        translations
        cookieConsentLabelNode {
          childMarkdownRemark {
            html
          }
        }
        cookieConsentButtonLabel
      }
      ko: datoCmsKrShared(locale: { eq: "ko" }) {
        logo {
          url
          alt
          title
        }
        mainNavLinks {
          link
          subLinks {
            link
            label
            descriptionNode {
              childMarkdownRemark {
                html
              }
            }
            image {
              url
              alt
              title
            }
          }
          label
        }
        demo {
          link
          label
        }
        socialMediaLabel
        socialMedia {
          title
          image {
            url
            alt
            title
          }
          accountLink {
            link
            label
          }
        }
        footerLogo {
          url
          alt
          title
        }
        exploreLabel
        exploreLinks {
          link
          label
        }
        wwdLabel
        wwdLinks {
          link
          label
        }
        legalLabel
        legalLinks {
          link
          label
        }
        globalSiteLabel
        globalSiteLink {
          link
          label
        }
        copyright
        translations
        cookieConsentLabelNode {
          childMarkdownRemark {
            html
          }
        }
        cookieConsentButtonLabel
      }
    }
  `)

  return { site, datoCmsSite, shared: locale === 'ko' ? ko : en }
}

const setViewportWidth = () => {
  const vw = document.documentElement.clientWidth / 100
  document.documentElement.style.setProperty('--vw', `${vw}px`)
}

const Layout = ({ pageTags, noIndex, location, children }) => {
  const siteComponents = useSiteComponents()

  useEffect(() => {
    setViewportWidth()
    const onResize = debounce(setViewportWidth, 50)
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize)
    }
    return () => window.removeEventListener('resize', onResize)
  }, [setViewportWidth])

  const [initialized, setInitialized] = useState(false)

  useEffect(() => setInitialized(true), [])

  /* Handling states and callbacks for global demo form / modal */
  const [demoForm, setDemoForm] = useState(false)
  const [enquiryForm, setEnquiryForm] = useState(false)

  useEffect(() => {
    setDemoForm(location.hash === '#request-demo')
    setEnquiryForm(location.hash === '#partnership-enquiry')
  }, [location])

  const handleDemoClose = useCallback(() => {
    setDemoForm(false)
    if (typeof window !== `undefined`) {
      window.history.pushState({}, document.title, window.location.pathname)
    }
  }, [location])

  const handleEnquiryClose = useCallback(() => {
    setEnquiryForm(false)
    if (typeof window !== `undefined`) {
      window.history.pushState({}, document.title, window.location.pathname)
    }
  }, [location])

  return (
    <div css={[tw`flex flex-col min-h-screen relative z-0`]}>
      <Global />
      <SEO
        pageTags={pageTags}
        faviconTags={get(siteComponents, 'datoCmsSite.faviconMetaTags.tags')}
        siteUrl={get(siteComponents, 'site.siteMetadata.siteUrl')}
        noIndex={noIndex}
      />
      <Header
        logo={get(siteComponents, 'shared.logo')}
        callToAction={get(siteComponents, 'shared.demo')}
        primaryLinks={get(siteComponents, 'shared.mainNavLinks')}
        secondaryLinks={get(siteComponents, 'shared.legalLinks')}
        socialMedia={get(siteComponents, 'shared.socialMedia')}
        copyright={get(siteComponents, 'shared.copyright')}
      />
      <div
        css={[
          tw`flex-auto pt-18 transition-opacity duration-300 lg:pt-20 bg-white`,
          !initialized && tw`opacity-0`,
        ]}
      >
        {children}
      </div>
      {initialized && (
        <Footer
          logo={get(siteComponents, 'shared.footerLogo')}
          links={compact([
            get(siteComponents, 'shared.wwdLinks'),
            get(siteComponents, 'shared.exploreLinks'),
            get(siteComponents, 'shared.legalLinks'),
          ])}
          linkHeaders={compact([
            get(siteComponents, 'shared.wwdLabel'),
            get(siteComponents, 'shared.exploreLabel'),
            get(siteComponents, 'shared.legalLabel'),
          ])}
          socialMediaLabel={get(siteComponents, 'shared.socialMediaLabel')}
          socialMedia={get(siteComponents, 'shared.socialMedia')}
          globalSiteLabel={get(siteComponents, 'shared.globalSiteLabel')}
          globalSite={get(siteComponents, 'shared.globalSiteLink')}
          copyright={get(siteComponents, 'shared.copyright')}
        />
      )}
      <CookieConsent
        label={get(siteComponents, 'shared.cookieConsentLabelNode')}
        buttonLabel={get(siteComponents, 'shared.cookieConsentButtonLabel')}
      />
      <DemoForm isOpened={demoForm} handleClose={handleDemoClose} />
      <EnquiryForm isOpened={enquiryForm} handleClose={handleEnquiryClose} />
    </div>
  )
}

Layout.defaultProps = {
  noIndex: false,
}

Layout.propTypes = {
  pageTags: PropTypes.object,
  noIndex: PropTypes.bool,
  location: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default Layout
