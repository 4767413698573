import { Global } from '@emotion/core'
import React from 'react'
import tw, { css, theme } from 'twin.macro'

export const fluid = (min, max) => {
  const minVw = theme`screens.xs`.slice(0, -2)
  const maxVw = theme`screens.xxl`.slice(0, -2)
  const minQuery = `@media screen and (min-width: ${minVw}px)`
  const maxQuery = `@media screen and (min-width: ${maxVw}px)`
  return {
    fontSize: `${min}px`,
    [minQuery]: {
      fontSize: `calc(${min}px + ${max - min} * ((100vw - ${minVw}px) / ${maxVw - minVw - 1}))`,
    },
    [maxQuery]: {
      fontSize: `${max}px`,
    },
  }
}

export const remToPx = (rem) => Number(rem.slice(0, -3)) * 16

export const emotion = {
  'text-display-lg': css`
    ${tw`font-light text-8xl tracking-display-lg leading-display-lg`}
  `,
  'text-display': css`
    ${tw`font-light text-7xl tracking-display leading-display`}
  `,
  subtitle: css`
    ${tw`font-normal leading-subtitle tracking-subtitle`}
    ${fluid(remToPx(theme`fontSize.mobile-xs`), remToPx(theme`fontSize.sm`))}
  `,
  'subtitle-lg': css`
    ${tw`font-normal leading-subtitle-lg tracking-subtitle-lg`}
    ${fluid(remToPx(theme`fontSize.mobile-sm`), remToPx(theme`fontSize.base`))}
  `,
  'body-fluid': css`
    ${tw`leading-md tracking-md text-grey`}
    ${fluid(remToPx(theme`fontSize.mobile-sm`), remToPx(theme`fontSize.base`))}
  `,
  'body-lg': css`
    ${tw`leading-lg tracking-lg text-grey`}
    ${fluid(remToPx(theme`fontSize.base`), remToPx(theme`fontSize.lg`))}
  `,
  overline: css`
    ${tw`text-sm font-medium uppercase leading-overline tracking-overline`}
  `,
  'text-vertical': css`
    ${tw`text-sm font-bold uppercase leading-vertical tracking-vertical`}
  `,
  caption: css`
    ${tw`text-sm uppercase leading-caption tracking-caption text-grey text-opacity-60`}
  `,
  h1: css`
    ${tw`font-light tracking-h1 leading-mobile-h1 lg:leading-h1`}
    ${fluid(remToPx(theme`fontSize.mobile-5xl`), remToPx(theme`fontSize.6xl`))}
  `,
  h2: css`
    ${tw`font-light tracking-h2 leading-mobile-h2 lg:leading-h2`}
    ${fluid(remToPx(theme`fontSize.mobile-4xl`), remToPx(theme`fontSize.5xl`))}
  `,
  h3: css`
    ${tw`font-light tracking-h3 leading-mobile-h3 lg:leading-h3`}
    ${fluid(remToPx(theme`fontSize.mobile-3xl`), remToPx(theme`fontSize.4xl`))}
  `,
  h4: css`
    ${tw`font-light tracking-h4 leading-mobile-h4 lg:leading-h4`}
    ${fluid(remToPx(theme`fontSize.mobile-2xl`), remToPx(theme`fontSize.3xl`))}
  `,
  h5: css`
    ${tw`font-light tracking-h5 leading-mobile-h5 lg:leading-h5`}
    ${fluid(remToPx(theme`fontSize.mobile-xl`), remToPx(theme`fontSize.2xl`))}
  `,
  h6: css`
    ${tw`font-light tracking-h6 leading-mobile-h6 lg:leading-h6`}
    ${fluid(remToPx(theme`fontSize.mobile-lg`), remToPx(theme`fontSize.xl`))}
  `,
  fluid: (min, max) => {
    const minVw = theme`screens.xs`.slice(0, -2)
    const maxVw = theme`screens.xxl`.slice(0, -2)
    return css`
      font-size: ${min}px;
      @media screen and (min-width: ${minVw}px) {
        font-size: calc(${min}px + ${max - min} * ((100vw - ${minVw}px) / ${maxVw - minVw - 1}));
      }
      @media screen and (min-width: ${maxVw}px) {
        font-size: ${max}px;
      }
    `
  },
  remToPx: (rem) => Number(rem.slice(0, -3)) * 16,
}

export default function Typography() {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Noto Sans';
          src: local('Noto Sans');
          font-display: fallback;
        }
        html {
          font-size: 16px;
        }
        body {
          ${tw`font-body leading-md tracking-md text-grey`}
          ${fluid(remToPx(theme`fontSize.base`), remToPx(theme`fontSize.lg`))}
        }
        b,
        strong {
          ${tw`font-bold`}
        }
        h1 {
          ${tw`font-light tracking-h1 leading-mobile-h1 lg:leading-h1`}
          ${fluid(remToPx(theme`fontSize.mobile-5xl`), remToPx(theme`fontSize.6xl`))}
        }
        h2 {
          ${tw`font-light tracking-h2 leading-mobile-h2 lg:leading-h2`}
          ${fluid(remToPx(theme`fontSize.mobile-4xl`), remToPx(theme`fontSize.5xl`))}
        }
        h3 {
          ${tw`font-light tracking-h3 leading-mobile-h3 lg:leading-h3`}
          ${fluid(remToPx(theme`fontSize.mobile-3xl`), remToPx(theme`fontSize.4xl`))}
        }
        h4 {
          ${tw`font-light tracking-h4 leading-mobile-h4 lg:leading-h4`}
          ${fluid(remToPx(theme`fontSize.mobile-2xl`), remToPx(theme`fontSize.3xl`))}
        }
        h5 {
          ${tw`font-light tracking-h5 leading-mobile-h5 lg:leading-h5`}
          ${fluid(remToPx(theme`fontSize.mobile-xl`), remToPx(theme`fontSize.2xl`))}
        }
        h6 {
          ${tw`font-light tracking-h6 leading-mobile-h6 lg:leading-h6`}
          ${fluid(remToPx(theme`fontSize.mobile-lg`), remToPx(theme`fontSize.xl`))}
        }
      `}
    />
  )
}
