import PropTypes from 'prop-types'
import React from 'react'
import { css, theme } from 'twin.macro'

const hamburgerStyle = css`
  width: 21px;
  height: 16px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: ${theme`colors.primary.500`};
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  span:nth-of-type(1) {
    top: 0px;
  }

  span:nth-of-type(2),
  span:nth-of-type(3) {
    top: 7px;
  }

  span:nth-of-type(4) {
    top: 14px;
  }
`

const openStyle = css`
  span:nth-of-type(1) {
    top: 7px;
    width: 0%;
    left: 50%;
  }

  span:nth-of-type(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  span:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  span:nth-of-type(4) {
    top: 7px;
    width: 0%;
    left: 50%;
  }
`

const HamburgerIcon = ({ isOpened }) => (
  <div css={[hamburgerStyle, isOpened && openStyle]}>
    <span />
    <span />
    <span />
    <span />
  </div>
)

HamburgerIcon.propTypes = {
  isOpened: PropTypes.bool,
}

HamburgerIcon.defaultProps = {
  isOpened: false,
}

export default HamburgerIcon
