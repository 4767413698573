import { Global } from '@emotion/core'
import React from 'react'
import tw, { css } from 'twin.macro'

// Localized styles due to the difference in charset
export default function Ko() {
  return (
    <Global
      styles={css`
        body {
          ${tw`font-body-ko`}
          word-break: keep-all;
        }
      `}
    />
  )
}
