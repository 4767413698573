import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import tw, { css, theme } from 'twin.macro'
import { global } from '../styles/global'
import { max } from '../utils/breakpoints'
import { LinkType } from '../utils/prop-types'
import Button from './button'
import LanguagePicker from './language-picker'
import Link from './link'
import MainNav from './main-nav'

const Header = ({ logo, callToAction, primaryLinks, secondaryLinks, socialMedia, copyright }) => {
  const [isOpened, setIsOpened] = useState(false) // mobile menu

  const getImage = () => {
    return isOpened ? (
      <img
        css={[tw`w-full`, { maxWidth: '138px' }]}
        src="https://www.datocms-assets.com/24091/1606478171-bolttechcyan.svg"
        alt={get(logo, 'alt')}
        title={get(logo, 'title')}
    />
    ):(
      <img
        css={[tw`w-full`, { maxWidth: '138px' }]}
        src={get(logo, 'url')}
        alt={get(logo, 'alt')}
        title={get(logo, 'title')}
      />
    ); 
  }

  return (
    <header
      css={[
        css`
          background: linear-gradient(
            to left,
            ${theme`colors.secondary.500`} 50%,
            ${theme`colors.white`} 50%
          );
          background-size: 200% 100%;
          background-position: left bottom;
          ${tw`fixed inset-x-0 top-0 z-20 transition-all duration-300 ease-out h-header-mobile lg:h-header-default`}
        `,
        isOpened &&
          css`
            ${max.lg} {
              ${tw`bg-right-bottom`}
            }
          `,
      ]}
    >
      <div css={[global`layout.container`, global`layout.grid`, tw`items-center h-full`]}>
        <Link
          to="/"
          style={css`
            ${tw`col-span-3 lg:col-span-2`}
          `}
          onClick={() => setIsOpened(false)}
        >
          {getImage()}
        </Link>
        <div
          css={tw`col-start-4 col-end-5 md:(col-start-8 col-end-9) lg:(col-start-3 col-end-12 h-full px-4) xl:(col-start-4 col-end-13 px-0)`}
        >
          <MainNav
            primaryLinks={primaryLinks}
            secondaryLinks={secondaryLinks}
            socialMedia={socialMedia}
            copyright={copyright}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
          />
        </div>

        <div
          css={[
            tw`hidden lg:(flex justify-end col-span-3 col-start-12 col-end-13) xl:(col-start-13 col-end-17)`,
          ]}
        >
          <Button
            type="primary"
            size="xs"
            label={callToAction.label}
            link={callToAction.link}
            theme="navy-cyan"
            style={tw`whitespace-no-wrap hidden xl:block`}
            hideCaret
          />
          <LanguagePicker style={[tw`ml-4`]} />
        </div>
      </div>
      <div
        css={[
          tw`h-px bg-line-grey absolute block bottom-0 inset-x-0 -z-10`,
          isOpened && tw`bg-secondary-500 lg:bg-line-grey`,
        ]}
      />
    </header>
  )
}

Header.propTypes = {
  logo: PropTypes.object,
  callToAction: PropTypes.object,
  primaryLinks: PropTypes.arrayOf(LinkType),
  secondaryLinks: PropTypes.arrayOf(LinkType),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string,
}

export default Header
