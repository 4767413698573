import PropTypes from 'prop-types'
import React from 'react'
import tw, { css, theme } from 'twin.macro'
import quote from '../images/icon-quote.svg'
import { RequiredHTMLType, StyleType } from '../utils/prop-types'
import { min } from '../utils/breakpoints'

export const typography = css`
  *:last-child {
    ${tw`pb-0 mt-0`}
  }
  ul,
  p {
    ${tw`pb-10`}
  }
  hr {
    ${tw`mt-10 mb-20`}
  }
  a {
    ${tw`font-medium text-primary-500 text-opacity-77 transition duration-300 ease-in-out`}
    text-decoration: underline solid ${theme`colors.primary.500`};
    transition-property: text-decoration, color;
    &:hover {
      ${min.lg} {
        text-decoration: underline solid transparent;
        ${tw`text-opacity-100`}
      }
    }
  }
  blockquote {
    &:before {
      content: url(${quote});
      ${tw`block mb-8`}
    }
  }
  table {
    ${tw`mb-10`}
    thead {
      ${tw`bg-primary-50`}
    }
    th,
    td {
      ${tw`py-2 px-4 border border-line-grey`}
    }
  }
`

export const emailStyle = css`
  p {
    ${tw`mb-2 last-of-type:mb-0`}
  }
  b, strong { 
    ${tw`text-base text-strong-tag font-bold`}
  }
  a {
    ${tw`font-bold text-primary-500 font-normal`}
    ${min.lg} {
      ${tw`overflow-hidden relative inline-block align-bottom`}
      &:after {
        ${tw`absolute inset-x-0 bottom-0 w-full opacity-0 transform -translate-x-full
          transition-transform duration-300 ease-in-out h-px bg-primary-500`}
        content: '';
      }
      &:hover {
        &:after {
          ${tw`opacity-100 transform translate-x-0`}
        }
      }
    }
  }
`

export const aboutBolttechStyle = css`
  p {
    ${tw`mb-2`}
  }
  b, strong { 
    ${tw`text-2xl text-primary-500 font-bold block mb-2`}
  }
  a {
    ${tw`font-bold text-primary-500 font-normal`}
    ${min.lg} {
      ${tw`overflow-hidden relative inline-block align-bottom`}
      &:after {
        ${tw`absolute inset-x-0 bottom-0 w-full opacity-0 transform -translate-x-full
          transition-transform duration-300 ease-in-out h-px bg-primary-500`}
        content: '';
      }
      &:hover {
        &:after {
          ${tw`opacity-100 transform translate-x-0`}
        }
      }
    }
  }
`

export const inlineTypography = css`
  p {
    ${tw`mb-2 last-of-type:mb-0`}
  }
  a {
    ${tw`font-bold text-primary-500`}
    ${min.lg} {
      ${tw`overflow-hidden relative inline-block align-bottom`}
      &:after {
        ${tw`absolute inset-x-0 bottom-0 w-full opacity-0 transform -translate-x-full
          transition-transform duration-300 ease-in-out h-px bg-primary-500`}
        content: '';
      }
      &:hover {
        &:after {
          ${tw`opacity-100 transform translate-x-0`}
        }
      }
    }
  }
`

export const diversityInclusionStyle = css`
  b, strong { 
    ${tw`font-bold block text-lg leading-8 text-diversity-strong mb-8`}
  }
  a {
    ${tw`font-medium text-base leading-6 text-strong-tag`}
  }
  a:hover {
    ${tw`text-primary-500 underline`}
  }
  li, p:not(:first-of-type){
    ${tw`mt-3`}
  }
`

export const blockquoteStyle = css`
  blockquote {
    &:before {
      content: url(${quote});
      ${tw`block mb-8`}
    }
  }
`

const Text = ({ content, parentEl, style, ...props }) => {
  const Parent = parentEl

  if (content && !content.childMarkdownRemark) {
    return (
      <Parent css={style} {...props}>
        {content}
      </Parent>
    )
  }

  if (content && content.childMarkdownRemark.html) {
    return (
      <Parent
        css={style}
        dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
        {...props}
      />
    )
  }

  return null
}

Text.propTypes = {
  content: RequiredHTMLType,
  parentEl: PropTypes.string,
  style: StyleType,
  props: PropTypes.object,
}

Text.defaultProps = {
  parentEl: 'div',
}

export default Text
